.m_proposals-proposals.m_proposals-proposals--compare {
  .card__content > p {
    padding-left: 1.5rem;
  }
}
.m_proposals-proposals.m_proposals-proposals--preview {
  .columns.large-9 > .card.card__content {
    padding: 1rem;
    .heading3 {
      margin-bottom: 0;
    }
    .author--inline {
      margin-bottom: 1rem;
    }
  }
  [type="submit"].button.small {
    @extend .btn;
    @extend .btn--primary;
    padding: 20px 80px !important;
    height: 53px !important;
  }
}

.m_proposals-proposals.m_proposals-proposals--complete {
  #address_input,
  label[for="proposal_category_id"],
  label[for="proposal_user_group_id"] {
    margin-top: 1rem;
  }
}

.m_proposals-proposals.m_proposals-proposals--new,
.m_proposals-proposals.m_proposals-proposals--complete,
.o-collaborative_drafts.o-collaborative_drafts--new,
.m_proposals-proposals.m_proposals-proposals--preview,
.m_proposals-proposals.m_proposals-proposals--preview,
.m_proposals-proposals.m_proposals-proposals--edit {
  .columns.large-3 {
    & > .section {
      display: none;
    }
    & > .show-for-large {
      & > div:first-child {
        display: none;
      }
      & .text-small {
        margin-top: 2.1rem;
        margin-bottom: 0.5rem;
        display: block;
        @extend .mini-title;
      }
    }
    .wizard__steps {
      & > li {
        @extend .body-4;
      }
      & > li::before {
        margin: 5px 4px 0 -6px;
      }
      & > li.step--past,
      & > li:not(.step--active) {
        color: var(--grey5);
        border-left-color: var(--grey5);
        &::before {
          background-color: var(--grey5);
        }
      }
    }
  }

  
  .columns.large-6 {
    form {
      padding: 1rem;
      max-width: 100% !important;
      label {
        margin-bottom: 0 !important;
      }
      .field > .data-picker .picker-values {
        padding: 0 !important;
        margin: 0 !important;
      }

      [type="submit"].button.small {
        @extend .btn;
        @extend .btn--primary;
        padding: 20px 80px !important;
        height: 53px !important;
      }

      .row.column {
        margin-top: 1rem;
      }
    }
  }
}
.m_proposals-proposals.m_proposals-proposals--edit {
  .edit_proposal .actions {
    margin-top: 2rem;
  }
}
.o-collaborative_drafts--index {
  .button.title-action__action {
    @extend .btn--secondary;
  }
  span.button.title-action__action {
    @extend .btn--disabled;
    border-color: var(--emphasis-lowest) !important;
  }
}

.o-proposals.o-proposals--index,
.o-collaborative_drafts--index {
  .collection-sort-controls > .column {
    min-width: 50%;
  }
  @include filters-area;
  #list-of-endorsements {
    .author-data.author-data--small {
      margin-bottom: 0.5rem;
    }
  }

  .dropdown.menu {
    transform: translateY(2px);
    z-index: 9999;
  }
  @include breakpoint(small only) {
    .title-action {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: flex-start;
      a {
        margin-left: 0 !important;
      }
    }
  }
}

.m_proposals-proposals.m_proposals-proposals--show,
.o-collaborative_drafts--show {
  .view-side > a:first-of-type {
    margin-bottom: 1.5rem;
    @extend .btn--primary;
    width: 100%;
    &:hover,
    &:focus {
      background-color: var(--primary-dark) !important;
      color: var(--on-primary) !important;
    }
  }
  .author-data__extra .link-alt {
    @extend .body-4;
    @extend .body-4-link;
    .icon {
      width: 12px;
      height: 12px;
    }
  }
  .tags--list {
    margin-top: 1rem;
  }
  .view-side {
    br {
      display: none;
    }
    .buttons__row {
      flex-direction: column;
      justify-content: stretch;
      gap: 16px;
      &::after {
        display: none;
      }
    }
    .button_to {
      padding-right: 0;
    }
    .card__content > div,
    .column.small-9.collapse,
    .column.collapse.small-3 {
      &,
      & > .button-group,
      & > .button,
      & > .button_to,
      & .button_to > .button {
        width: 100% !important;
      }
    }
  }
}
