.decidimGeo__container .js-decidimgeo.decidimgeo__map {
  .leaflet-control.decidimGeo__filter {
    margin-bottom: 16px;
  }
  .decidimGeo__filterModal__container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2500;
    display: flex;
    align-items: center;
    justify-content: center;
    &--closed {
      display: none;
    }
  }
  .decidimGeo__filterModal__form {
    flex: 1;

    max-height: 400px;
    background: white;
    border-radius: 8px;
    border-top: #cccccc 1px solid;
    border-bottom: #cccccc 1px solid;

    list-style: none;
    transition: 0.3s;
    background: white;
    margin: 48px 16px;
    padding: 8px;

    position: absolute;
    z-index: 9999;
    box-shadow: 0 6px 16px rgba(0,0,0,0.6);
    &--closed {
      display: none;
    }
    &--open {
      display: block;
    }
  }

  .decidimGeo__filterToggle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 16px;
    background: white;
    border-radius: 4px;
    padding: 4px;
    &--disabled .decidimGeo__filterToggle__button,
    &--disabled .decidimGeo__filterToggle__counter {
      opacity: 0.3;
      cursor: initial;
    }
  }

  .decidimGeo__filterToggle__button {
    @include decidimGeo__clickableText;
    padding: 8px;
    text-transform: uppercase;
    font-family: $header-font-family;
    letter-spacing: 0.2px;
    margin-bottom: 0;
    color: #808080 !important;
  }

  .decidimGeo__filterToggle__counter {
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(50%) translateY(-50%);
    display: flex;
    width: 20px;
    height: 20px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    background: var(--primary);
    color: white;
    &--hidden {
      display: none;
    }
  }

  .decidimGeo__filterModal__fieldset {
    display: flex;
    flex-direction: column;
    gap: 8px;
    list-style: none;
    margin: 0;
    padding: 8px 8px;
    margin-bottom: 16px;
  }
  .decidimGeo__filterModal__actions {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0 16px;
  }
  .decidimGeo__filterModal__field {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }
  .decidimGeo__filterModal__label {
    @include decidimGeo__clickableText;
    color: #1a181d !important;
    padding: 8px 16px 8px 8px;
    font-size: 14px;
    text-wrap: nowrap;
  }

  .decidimGeo__filterModal__select,
  .decidimGeo__filterModal__select:focus {
    margin-bottom: 0;
    max-width: 100%;
    border: 0;
    text-align: right;
    box-shadow: none;
    color: #1a181d;
  }
  .decidimGeo__filterModal__resetBtn,
  .decidimGeo__filterModal__applyBtn {
    @include decidimGeo__clickableText;
    padding: 8px 16px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    border-radius: 48px;
  }
  .decidimGeo__filterModal__resetBtn {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    svg {
      transform: translateX(-4px);
    }
    &:focus {
      outline: 0;
    }
  }
  .decidimGeo__filterModal__applyBtn {
    background: rgba(var(--primary-rgb), 0.1);
    &--disabled {
      background: #59595940;
      color: #595959 !important;
      cursor: initial !important;
    }
  }

  .decidimGeo__filterModalHelpText__label {
    padding: 4px 16px;
    font-weight: 600;
    font-size: 11px;
    color: #595959;
    letter-spacing: 1%;
    &--errors {
      color: #ec5840;
    }
  }
}
