.better-accountability .result_card {
  margin-bottom: $card-margin-bottom;
  background: white;
  border: #e8e8e8;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  max-width: 100%;

  &__header{
    border-bottom: 1px solid #e8e8e8;
  }
  &__headertext {
    overflow: hidden; 
    margin-bottom: 0;
    white-space: nowrap; 
    text-overflow: ellipsis; 
    display: inline-block; 
    max-width: 100%; 
  }
  &__description {
    flex: 1;
  }
  &__header, &__description {
    padding: 1rem 1.5rem;
  }

  &__metas {
    flex: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__actions {
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    a {
      @extend .link;
      flex: 0;
      white-space: nowrap;
      display: inline-block;
    }
  }
  &__metas,
  &__actions {
    border-top: 1px solid #e8e8e8;
  }

  &__status {
    padding: 1rem 1.5rem;
  }
  &__statustitle {
    font-weight: 600;
  }
  &__progress {
    padding: 0.75rem 1.5rem;
    display: flex;
    gap: 0.75rem;
    align-items: center;
  }
  &__progressbar {
    flex: 1;
    margin-bottom: 0;
    &,
    .progress-meter {
      height: 0.5rem !important;
      min-height: 0.5rem !important;
    }
  }

  &__progressnumber {
    font-weight: 600;
  }
}
