.process-header__phase {
  padding: 1rem;

  > div {
    margin-bottom: 1rem;
  }

  @include breakpoint(small only) {
    .show-for-medium {
      display: inline-flex !important;
    }
  }
}

.process-header__progress {
  > ol {
    margin-bottom: 0.75rem;
  }
}
