.better_accountability__goback {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;
}

.better_accountability__menu {
  float: right;
  display: block;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 0.5rem;
  background: #e8e8e8;
  &--mobile {
    margin-top: 2rem;
  }
  ul.menu {
    margin-bottom: 0 !important;
  }
  .menu li > a {
    padding: 1rem;
  }
  .menu li.active > a {
    background-color: var(--primary);
    color: white !important;
    &.active {
      background-color: var(--primary);
    }
  }
  .menu li:first-child a {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .menu li:last-child a {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
