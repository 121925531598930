.omnipresent-banner{
    background-color: var(--primary);
    color: $white;
    text-align: center;
    padding: 1rem;
    font-size: 1.2em;
  
    .omnipresent-banner-title{
        @extend .body-4;
        @extend .body-4-semibold;
      margin-right: 1em;
    }
    
  
    .omnipresent-banner-short-description{
        @extend .body-4;
      margin-right: .5em;
      margin-left: 1em;
    }
  
    a{
      color: var(--white) !important;
    }
  
    a:hover,
    a:focus{
      color: var(--white-50) !important;
    }
  }
  