@import "./decidim_geo_mixins";
@import "./decidim_geo_globals";
@import "./decidim_geo_zoom_controls";
@import "./decidim_geo_scope_dropdown";
@import "./decidim_geo_filter_dropdown";
@import "./decidim_geo_drawer_list";

@import "./decidim_geo_mobile";
@import "./decidim_geo_pageName";

.decidimGeo__container {
  position: relative;
  padding: 16px;

  .js-decidimgeo.decidimgeo__map {
    display: flex;
    min-height: 540px;
    height: 70vh;
    width: 100%;
    max-width: 100%;
    border: 1px solid transparent;
    border-radius: 8px;
    background: transparent;
  
    * {
      box-sizing: border-box;
    }
  }
}
