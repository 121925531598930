.leaflet-control-container {
  .leaflet-control-zoom-fullscreen.fullscreen-icon {
    background-image: url("images/decidim/geo/fullscreen.svg");
    box-sizing: border-box;
    background-size: 16px 16px;
    background-position: center center;
    color: var(--primary);
    fill: var(--primary) !important;
  }
}

/* Safari still needs this vendor-prefix: https://caniuse.com/mdn-css_selectors_fullscreen */
/* stylelint-disable-next-line selector-no-vendor-prefix */
.leaflet-container:-webkit-full-screen {
  width: 100% !important;
  height: 100% !important;
  z-index: 99999;
}

.leaflet-container:fullscreen {
  width: 100% !important;
  height: 100% !important;
  z-index: 99999;
}

.leaflet-pseudo-fullscreen {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 99999;
}
