.projects-grid {
  display: grid;
  grid-template-columns: repeat(1, 100%);
  grid-template-rows: repeat(12, auto);
  gap: 1rem;
  @include breakpoint(medium up) {
    grid-template-columns: repeat(3, 33.33333%);
    grid-template-rows: repeat(4, auto);
  }
  &__title {
    margin-bottom: 1rem;
  }
  &__pagination {
    text-align: center;
  }
}
