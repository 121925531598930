.decidimGeo__container .js-decidimgeo.decidimgeo__map {
  .decidimGeo__drawerHeader__list {
    width: 100%;
    position: absolute;
    z-index: 9999;
    background: white;
    margin-top: 48px;
    width: 300px;
    margin-left: 15px;
    max-height: 75vh;
    border-top: #cccccc 1px solid;
    border-bottom: #cccccc 1px solid;
    overflow-y: auto;
    list-style: none;
    &--closed {
      max-height: 0;
      border-top-color: transparent;
      border-top-width: 0;
      margin-bottom: 0;
    }
    transition: 0.3s;
  }
  .decidimGeo__drawerHeader,
  .decidimGeo__drawerHeader.leaflet-control {
    @include decidimGeo__border;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: block;
    width: 300px;
    background: #fff;
    position: absolute;
    z-index: 9999;
  }

  .decidimGeo__drawerHeader__heading {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .decidimGeo__drawerHeaderRow {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;

    &--hidden {
      display: none;
    }
  }

  .decidimGeo__drawerHeader__drawerToggle {
    padding: 6px;
    display: block;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    &--open {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    cursor: initial;
    &--link {
      color: var(--primary);
    }
    &--text {
      cursor: initial !important;
    }
  }
  .decidimGeo__drawerHeaderRow {
    &--aside-toggle {
      display: block;
      color: rgba(0, 0, 0, 0.24);
      text-align: center;
      display: inline-block;
    }
    &--hidden {
      display: none;
    }
  }
  .decidimGeo__modalOverlay {
    &--open {
      display: block;
    }

    z-index: 2400;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
    opacity: 0.3;
    display: none;
  }
  .decidimGeo__drawerHeader__backButton {
    display: none;
    margin-top: 3px;
  }
  .decidimGeo__drawerHeader__backButton {
    @include decidimGeo__clickableText;
    gap: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 16px;
    span {
      padding: 8px 32px 8px 0px;
    }

    &--hidden {
      visibility: hidden !important;
    }
  }

  .decidimGeo__drawerHeader__reset {
    @include decidimGeo__clickableText;
    padding: 8px 16px 8px 8px;
    float: right;
    text-transform: uppercase;
    font-family: $header-font-family;
    letter-spacing: 0.2px;

    &--hidden {
      display: none;
    }
  }

  .decidimGeo__drawerHeader__listItem {
    @include decidimGeo__clickableText;
    margin: 8px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: space-between;
    .decidimGeo__drawerHeader__listItemtxt {
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .decidimGeo__drawerHeader__listItemIcn {
      color: var(--primary);
      visibility: hidden;
    }
    &--active {
      color: #808080 !important;
      .decidimGeo__drawerHeader__listItemIcn {
        visibility: visible;
      }
    }
  }
}
