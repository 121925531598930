@mixin decidimGeo__drawerContainer {
  width: 299px;
  height: auto;
  max-height: 60vh;
  border-top: #cccccc 1px solid;
  overflow-y: auto;
  list-style: none;
  margin: 0;
  padding: 0;

  transition: 0.3s;

  &--closed {
    width: 300px;
    height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  &--hidden {
    display: none;
  }
  @include breakpoint(medium down) {
    width: 100%;
    background: lightgray;
    background-color: transparent;
  }
}

@mixin decidimGeo__clickableText {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  color: var(--primary) !important;
  cursor: pointer;
}

@mixin decidimGeo__border {
  border-radius: 4px;
  overflow: auto;
  box-shadow: 0px 2px 4px 0px #00000014;
}

@mixin decidimGeo__meetingDateItem {
  text-align: center;
  color: #726a70;
  padding: 0.5em;
  margin-right: -1px;
  margin-top: -1px;
  border-top: 1px solid #e8e8e8;
}
